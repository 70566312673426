@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  font-family: 'Poppins', sans-serif !important;
}

body {
  background-image: url('./images/bg.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
  font-weight: 300;
}

a {
  text-decoration: none !important;
}

@media screen and (min-width: 992px) {
  body {
    background-size: cover;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
  font-weight: 700 !important;
}

.sectionPadding {
  padding: 100px 0;
}

.t-white {
  color: white !important;
}

.t-dark {
  color: #00021E !important;
}

.headingText {
  font-size: 34px;
  font-weight: 600;
}

a.link {
  color: #CFDE41;
  cursor: pointer;
  text-decoration: none;
  font-size: 13px;
}

a.link:hover {
  color: #55AEBD;
}

/* Navbar */
.navbar
{
    background-color: rgba(255, 255, 255, 0) !important;
}

.navbar-toggler {
  border: 1px solid white;
}

.navbar-nav {
  background-color: #040447;
  opacity: .9;
}

.navbar-nav.navbar-nav-scroll {
  max-height: 100% !important;
}

a.nav-link {
  color: white !important;
  font-weight: 500;
  font-size: 13px !important;
  border-bottom: 1px solid #1f244e;
  padding: 10px 0 10px 30px;
}

a.nav-link:hover,
a.nav-link:active {
  color: #CFDE41 !important;
}

@media screen and (min-width: 992px) {
  .navbar-nav {
    background-color: transparent;
    margin: 0 auto !important;
    opacity: 1;
  } 

  a.nav-link {
    border-bottom: none;
  }
}

/* Header */
.siteHeader {
  min-height: 100vh;
}

.headerText {
  color: white !important;
  font-size: 14px;
  line-height: 26px;
  font-weight: 400 !important;
  max-width: 800px;
}

.stitchedBtn {
  padding: 8px 80px;
  background-color: transparent;
  background-image: linear-gradient(170deg, #CFDE41 0%, #00ADBD 100%);
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: white;
  border-radius: 20px 20px 20px 20px;
  color: white;
  font-weight: 600;
}

.stitchedBtnTransparent {
  padding: 8px 72px;
  background-color: transparent;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: white;
  border-radius: 20px 20px 20px 20px;
  color: white;
  font-weight: 600;
}

.stitchedBtn:hover,
.stitchedBtnTransparent:hover {
  background-image: linear-gradient(270deg, #CFDE41 0%, #00ADBD 100%);
}

.headerBtn1,
.headerBtn2 {
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
}


.stitchedGradient {
  background-color: transparent;
  background-image: linear-gradient(170deg, #CFDE41 0%, #00ADBD 100%);
}

.fingerprintHeading {
  display: block;
  text-align: center;
  font-weight: 600;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 5px;
  margin-left: 11px;
  margin-right: 11px;
}

.fingerprintValue {
  display: block;
  text-align: center;
  border-radius: 10px;
  margin: 0 12px;
  word-break: break-all;
}

.appDownloadLinks {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.appDownloadImg {
  width: 180px;
  height: auto;
}


@media screen and (min-width: 768px) {
  .headerBtn1 {
    margin-left: auto;
    margin-right: 0;
  }

  .headerBtn2 {
    margin-right: auto;
    margin-left: 0;
  }

  .headerText {
    font-size: 15px;
    font-weight: 400 !important;
  }

  .fingerprintWrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }

  .fingerprintHeading {
    width: 250px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: 2px solid white;
    margin-top: 15px;
    margin-bottom: 5px;
    padding: 0 10px;
    margin-left: 0;
    margin-right: 2px;
  }

  .fingerprintValue {
    width: 450px;
    text-align: left;
    padding-left: 5px;
    margin-top: 15px;
    margin-bottom: 5px;
    margin-left: 2px;
    margin-right: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 2px solid white;
  }

  .appDownloadImg {
    width: 200px;
    height: auto;
  }

}

/* Tokenomics */
.tokenomics p {
  margin-top: 30px;
  margin-bottom: 0;
  font-weight: 300;
}

.item-box {
  margin-bottom: 20px;
}

.item-box-top {
  background-color: #CFDE41;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  border-left: 2px solid #55AEBD;
  border-right: 2px solid #55AEBD;
  margin-bottom: 4px;
}

.item-box-top h3 {
  font-size: 18px;
  font-weight: 600;
  padding: 4px 0;
  margin: 0;
}


.item-box-bottom {
  background-color: #55AEBD;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
  border-left: 2px solid #CFDE41;
  border-right: 2px solid #CFDE41;
}

.item-box-bottom h4 {
  font-size: 14px;
  font-weight: 400;
  padding: 6px 0;
}

@media screen and (min-width: 992px) {
  .item-box {
    margin-bottom: 20px;
  }

  .tokenomics p {
    padding: 0 100px;
    margin-bottom: 40px;
  }

}

.blue-box {
  background: #040447;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
  min-height: 102px;
  padding-top: 17px;
  margin: 10px 0;
}

.blue-box h3 {
  margin-top: 15px;
  font-size: 17px;
  font-weight: 400 !important;
}

.blue-box h4 {
  font-size: 13px;
  font-weight: 300 !important;
}

@media screen and (min-width: 992px) {
  .blue-box {
    margin: 20px 0;
  }
}


/* About */
.about-heading {
  text-align: center;
  margin-bottom: 20px;
}

.about-para {
  padding: 0 20px;
  font-weight: 300;
  line-height: 30px;
}

.blank-box {
  background-color: rgba(12, 6, 68, 0.5);
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
  padding: 50px 20px;
  border-radius: 15px;
  margin-bottom: 30px;
}

.blank-box h2 {
  text-align: center;
}

.blank-box p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  margin-top: 20px;
  line-height: 33px;
}


@media screen and (min-width: 992px) {
  .about-heading {
    text-align: left;
  }

  .about-para {
    padding: 0;
    font-weight: 400;
  }

  .about-section-logos {
    margin-top: 40px !important;
  }

  .blank-box {
    font-weight: 400 !important;
    height: 600px;
  }

  .blank-box h2 {
    text-align: left;
  }

}

@media screen and (min-width: 1200px) {
  .blank-box {
    height: 530px;
  }
}

/* Features */
.features {
  padding-bottom: 30px;
}

.features h3{
  font-size: 25px;
  font-weight: 400 !important;
  padding: 20px 0 10px;
}

.features p {
  font-size: 14px;
  font-weight: 300;
  padding: 0 70px;
}

@media screen and (min-width: 768px) {
  .features p {
    padding: 0 30px;
  }
}

@media screen and (min-width: 1200px) {
  .features h3 {
    margin-top: 10px;
  }

  .features p {
    margin-top: 10px;
    padding: 0;
  }
}

/* Roadmap */
.roadmap {
  margin-left: 40px;
}

.roadmap-heading {
  background: transparent;
  background-image: linear-gradient(170deg, #020447 0%, #67C7BC 100%); 
  writing-mode: vertical-lr;
  text-orientation: upright;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: -3px;
  padding: 4px;
  border-style: solid;
  border-width: 0px 2px 0px 2px;
  border-color: #CFDE41;
  border-radius: 10px 0px 10px 0px;
  margin-right: 20px;
}

.roadmap-btn {
  display: block;
  width: 300px;
  background: transparent;
  border: 2px solid #00ADBD;
  border-radius: 10px;
  color: white;
  padding: 6px 0 6px 20px;
  font-weight: 500;
  font-size: 22px;
  text-align: left;
  margin-bottom: 10px;
}

.roadmap-text p {
  padding: 3px 0;
  margin: 0;
  font-size: 15px;
}

@media screen and (min-width: 768px) {
  
  .roadmap:nth-child(odd) {
    margin-left: 25%;
  }

  .roadmap:nth-child(even) {
    margin-left: 50%;
  }

  .roadmap-btn {
    width: 100px;
  }
}

/* Footer */
.hr {
  border-top: 2px solid #6EC1E4;
  opacity: 1;
}

.footer-chip-logo {
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}

.footer-para {
  text-align: center;
}

.copyright-text {
  font-size: 14px;
  text-align: center;
}

.mail-link {
  color: #CFDE41;
  cursor: pointer;
  text-align: center;
  display: block;
  margin-bottom: 30px;
}

.footer-block {
  padding: 0 20px;
  margin-bottom: 30px;
}

.footer-list {
  list-style: none;
  padding: 0;
}

.footer-list li {
  padding-bottom: 7px;
  opacity: .9;
  font-size: 15px;
}

.footer-list li a {
  text-decoration: none;
  color: white;
  cursor: pointer;
  font-weight: 400 !important;
}

.footer-list li a:hover,
.footer-list li a:active {
  color: #CFDE41 !important;
}


@media screen and (min-width: 768px) {
  .footer-chip-logo {
    margin: 0;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .footer-para {
    text-align: left;
  }

  .mail-link {
    text-align: left;
  }

  .footer-block {
    padding: 0 30px;
    margin-top: 70px;
  }
  
  .copyright-text {
    text-align: left !important;
  }

}